// Import the functions you need from the SDKs you need
import { getApps, initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import {  getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";



//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDajP2PUFvM87E4fj7FZ42_TWqVXgckhUU",
  authDomain: "appletoncc-6261b.firebaseapp.com",
  databaseURL: "https://appletoncc-6261b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "appletoncc-6261b",
  storageBucket: "appletoncc-6261b.appspot.com",
  messagingSenderId: "652074739848",
  appId: "1:652074739848:web:2444ac74b8e6bef226973d",
  measurementId: "G-LG4W1W5MLX"
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];
const db = getFirestore(app);
const messaging = getMessaging(app);
const auth = getAuth(app);

export { db, messaging, auth, app };

// Request notification permission and get the token
export async function requestPermission() {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      const token = await getToken(messaging, {
        vapidKey: "BJ1syL_LZkDUHEDzeQQ1MCk3mxW-TgZVRV5750BCD0nBqtfy8C34Ea-JyTyrEf0OYrQYu-_UynwUkFzDeODBcHU",
        serviceWorkerRegistration: registration
      });

      if (token) {
        console.log("Notification token:", token);
        // Save token to Firestore or your desired database
      } else {
        console.error("Failed to retrieve FCM token.");
      }
    } else {
      console.error("Notification permission not granted.");
    }
  } catch (error) {
    console.error("Error during notification permission request:", error);
  }
}