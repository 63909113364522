import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../../src/router';

import '@shoelace-style/shoelace/dist/components/button/button.js';

@customElement('app-logo')
export class AppLogo extends LitElement {

  @property({ type: Boolean }) enableBack: boolean = false;

  static styles = css`
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: var(--app-color-primary);
      color: white;
      height: 4em;
      padding-left: 16px;
      padding-top: 12px;
      z-index: 80;

      position: fixed;
      left: env(titlebar-area-x, 0);
      top: env(titlebar-area-y, 0);
      height: env(titlebar-area-height, 100px); /* Adjusted height */
      width: env(titlebar-area-width, 100%);
      -webkit-app-region: drag;

      /* Adjust background properties */
      background-color: var(--app-background-color);
    }

    #back-button-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .logo-container {
      position: absolute;
      right: 20px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 20px;
    }

    .logo-container img {
      height: 60px; /* Adjust logo size as necessary */
      width: auto;
    }

    sl-button {
      background-color: #fff;
      color: var(--app-color-primary);
      border: 1px solid var(--app-color-primary);
      padding: 0.5em 1em;
      border-radius: 4px;
      font-weight: bold;
    }

    @media (prefers-color-scheme: light) {
      header {
        color: black;
      }

      nav a {
        color: initial;
      }
    }
  `;

  render() {
    return html`
      <header>

        <div id="back-button-block">
          ${this.enableBack
            ? html`
                <sl-button href="${resolveRouterPath()}">
                  Back
                </sl-button>
              `
            : null}
          <h1>${this.title}</h1>
        </div>

        <!-- Logo on the right -->
        <div class="logo-container">
          <img src="/assets/images/appletonlogo.png" alt="Club Logo" />
        </div>
      </header>
    `;
  }
}
