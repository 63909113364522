import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { getFirestore, collection, query, where, orderBy, getDocs, getDoc, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { classMap } from 'lit/directives/class-map.js';

@customElement('app-availability')
export class AppAvailability extends LitElement {
  @state() items: any[] = []; // Will hold both fixtures and events

  private db = getFirestore();
  private auth = getAuth();

  static styles = css`
    :host {
      color: #333;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      padding: 20px;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      line-height: 1.5;
      margin-bottom: 10px;
      background: white;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0,0,0,0.1);
      transition: background-color 0.3s;
    }

    .item.fixture {
      background-color: white;
    }

    .item.event {
      background-color: #e6ffe6; /* Light green background for events */
    }

    .item:hover {
      transform: translateY(-2px);
    }

    .item-info {
      flex-grow: 1;
      margin-right: 20px;
      color: #444;
    }

    .item-info div {
      margin-bottom: 4px;
    }

    .item-date, .item-opponent {
      font-size: 0.95em;
      color: #666;
    }

    .item-opponent {
      font-size: 1.1em;
      font-weight: bold;
      color: #333;
    }

    .item-venue, .item-time {
      font-size: 0.9em;
      color: #777;
    }

    .availability-toggle {
      padding: 12px 20px;
      flex: none;
      height: 1.5;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-weight: bold;
      transition: background-color 0.3s, transform 0.2s;
      text-align: center;
    }

    .available {
      background-color: #4CAF50;
      color: white;
    }

    .not-available {
      background-color: #F44336;
      color: white;
    }

    .available:hover, .not-available:hover {
      opacity: 0.85;
      transform: translateY(-2px);
    }

    .first-stat {
      padding-top: 100px;
    }

    .availability-header {
      text-align: center;
      padding: 16px;
      margin-bottom: 32px;
    }

    .availability-title {
      font-size: 1.8rem;
      color: #206e3a;
    }

    .availability-description {
      font-size: 1rem;
      color: #555;
      margin-top: 8px;
    }
  `;

  connectedCallback() {
    super.connectedCallback();
    this.fetchItems();
  }

  async fetchItems() {
    const now = new Date();
    const user = this.auth.currentUser;

    const fixturesRef = collection(this.db, 'Fixtures');
    const eventsRef = collection(this.db, 'Events');

    // Fetch future fixtures
    const fixturesQuery = query(fixturesRef, where('date', '>', now), orderBy('date', 'asc'));
    const fixturesSnapshot = await getDocs(fixturesQuery);

    // Fetch future events
    const eventsQuery = query(eventsRef, where('date', '>', now), orderBy('date', 'asc'));
    const eventsSnapshot = await getDocs(eventsQuery);

    const fixturePromises = fixturesSnapshot.docs.map(async (docSnapshot) => {
      const data = docSnapshot.data();
      let isAvailable = undefined;

      if (user) {
        const userAvailabilityRef = doc(this.db, `Fixtures/${docSnapshot.id}/playerlist/${user.uid}`);
        const userAvailabilityDoc = await getDoc(userAvailabilityRef);
        if (userAvailabilityDoc.exists()) {
          isAvailable = userAvailabilityDoc.data().availability;
        }
      }

      return {
        id: docSnapshot.id,
        date: data.date.toDate(), // Convert Firestore Timestamp to Date
        opponent: data.opponent,
        venue: data.venue,
        time: data.time,
        isAvailable,
        type: 'fixture', // Identify as fixture
      };
    });

    const eventPromises = eventsSnapshot.docs.map(async (docSnapshot) => {
      const data = docSnapshot.data();
      let isAvailable = undefined;

      if (user) {
        const userAvailabilityRef = doc(this.db, `Events/${docSnapshot.id}/playerlist/${user.uid}`);
        const userAvailabilityDoc = await getDoc(userAvailabilityRef);
        if (userAvailabilityDoc.exists()) {
          isAvailable = userAvailabilityDoc.data().availability;
        }
      }

      return {
        id: docSnapshot.id,
        date: data.date.toDate(), // Convert Firestore Timestamp to Date
        event: data.event,
        address: data.address,
        time: data.time,
        isAvailable,
        type: 'event', // Identify as event
      };
    });

    // Combine fixtures and events into one array
    const fixtures = await Promise.all(fixturePromises);
    const events = await Promise.all(eventPromises);

    // Combine both arrays and sort by date
    this.items = [...fixtures, ...events].sort((a, b) => a.date - b.date);
  }

  async toggleAvailability(itemId: string, isAvailable: boolean, type: string) {
    const user = this.auth.currentUser;
    if (!user) {
      console.error("No user is signed in.");
      return;
    }

    const collectionType = type === 'fixture' ? 'Fixtures' : 'Events';
    const userAvailabilityRef = doc(this.db, `${collectionType}/${itemId}/playerlist/${user.uid}`);

    try {
      await setDoc(userAvailabilityRef, { availability: isAvailable }, { merge: true });

      // Update local state to reflect the change
      this.items = this.items.map(item => {
        if (item.id === itemId) {
          return { ...item, isAvailable };
        }
        return item;
      });
      this.requestUpdate(); // Request update to reflect changes in the DOM
    } catch (error) {
      console.error("Error updating availability:", error);
    }
  }

  render() {
    return html`
      <app-header pageTitle="Availability ✔️"></app-header>
      <div class="top-stats first-stat">
        ${this.items.map(item => html`
          <div class=${classMap({'item': true, 'fixture': item.type === 'fixture', 'event': item.type === 'event'})}>
            <div class="item-info">
              <div class="item-date">${item.date.toDateString()}</div>
              ${item.type === 'fixture' ? html`
                <div class="item-opponent">${item.opponent}</div>
                <div class="item-venue">Venue: ${item.venue}</div>
              ` : html`
                <div class="item-opponent">${item.event}</div>
                <div class="item-venue">Address: ${item.address}</div>
              `}
              <div class="item-time">Time: ${item.time}</div>
            </div>
            <button class=${classMap({'availability-toggle': true, 'available': item.isAvailable === true})}
              @click="${() => this.toggleAvailability(item.id, true, item.type)}">
              <span>Available</span>
            </button>
            <button class=${classMap({'availability-toggle': true, 'not-available': item.isAvailable === false})}
              @click="${() => this.toggleAvailability(item.id, false, item.type)}">
              <span>Not Available</span>
            </button>
          </div>
        `)}
      </div>
    `;
  }
}
