import { LitElement, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { styles } from '../../public/styles/shared-styles';
import { router, resolveRouterPath } from '../router';
import './firebase-config';
import './app-login';
import './app-batting.ts';
import './app-availability';  // Adjust the path as necessary
import './app-events';  // Adjust the path as necessary
import './app-results-fixtures-carousel.js';
import { getAuth, onAuthStateChanged, signOut, User } from 'firebase/auth';
import { collection, getFirestore, getDocs, query, orderBy, limit, initializeFirestore, persistentLocalCache, persistentMultipleTabManager, doc, getDoc } from 'firebase/firestore';

import { getApp } from 'firebase/app';
let firestoreInitialized = false;
@customElement('app-home')
export class AppHome extends LitElement {
  @state() isSidebarOpen = false;
  @state() isLoggedIn = false;
  @state() firstName = '';
  @state() user: User | null = null;
  @state() clubRunsScored = 0;
  @state() clubWickets = 0;
  @state() clubGames = 0;
  @state() clubCatches = 0;
  @state() userType = '';
  @state() newsItems: Array<any> = [];
  @state() isLoadingMore = false;
  @state() lastVisible: any = null;
  @state() activeTab = 'news'; // Default to 'news'
  @state() showInstallButton = true;

  private deferredPrompt: any = null;

  static styles = [
    styles,
    css`
      :host {
        --sidebar-width: 250px;
        --sidebar-background-color: #f1f1f1;
        --sidebar-link-color: black;
        --sidebar-link-hover-color: white;
        --sidebar-link-hover-background-color: #555;
        background-image: url('/assets/images/background.jpg');
        color: black;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        overflow-x: hidden; /* Prevent horizontal scroll */
      }

      body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        overflow-x: hidden; /* Prevent horizontal scroll */
      }

      /* Ensure that content fits the width and is centered */
      .container, .tab-content {
        width: 100%;
        max-width: 1200px; /* Set a max width for large screens */
        margin: 0 auto; /* Center the container */
        padding: 0 16px; /* Small padding to avoid touching the edges */
        box-sizing: border-box;
      }

      .tabs {
        display: flex;
        justify-content: space-around;
        background-color: #4CAF50;
        margin: 0 auto;
        max-width: 1200px; /* Ensures tabs are centered within max width */
      }

      .tab {
        padding: 15px;
        cursor: pointer;
        color: white;
        font-weight: bold;
        flex: 1;
        text-align: center;
      }

      .tab.active {
        background-color: #2E7D32;
      }

      .first-stat {
        padding-top: 115px; /* Adjust the value based on your header's height */
      }

      .stat-card {
        background-color: white;
        border-radius: 10px;
        padding: 25px;
        text-align: center;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        width: 100%;
        color: #206e3a;
        display: flex;
        align-items: center;
        justify-content: space-between;

      }

      .stat-icon {
        font-size: 1.5rem;
        color: var(--primary-color);
        margin-right: 10px;
      }

      .stat-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
      }

      .stat-value {
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--primary-color);
      }

      .stat-label {
        font-size: 0.9rem;
        color: var(--text-color);
      }

  .stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1, 1fr)); /* Responsive grid */
  gap: 36px;
  margin-top: 20px;
  width: 100%; /* Ensure it takes up full width like tabs */
  max-width: 900px; /* Same max-width as tabs */
  padding: 0 16px; /* Consistent padding with tabs */
  box-sizing: border-box;
  margin-left:  auto;
  margin-right: auto;
}

      .news-date {
        font-size: 0.85rem;
        color: #666;
        margin-top: 4px;
      }

      .important-message-container {
  background-color: white;
  padding: 15px;
  text-align: center;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 1200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.important-message-container .message {
  font-size: 1.2rem;
  color: #206e3a;
}

.link-to-awards {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.link-to-awards:hover {
  color: #0056b3;
  text-decoration: underline;
}

 h3 {
      color: #206e3a;
      font-size: 1.5em;

      text-align: center;
    }

    .skeleton {
  background-color: #ddd;
  animation: shimmer 1.2s infinite linear;
  height: 20px;
  width: 100%;
  border-radius: 4px;
}

  .install-button-container {
        display: flex;
        justify-content: center;
        margin: 20px 0;
      }

      .install-button {
        background-color: #4caf50;
        color: white;
        border: none;
        border-radius: 4px;
        padding: 10px 20px;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }

      .install-button:hover {
        background-color: #2e7d32;
      }

@keyframes shimmer {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}


    `,
  ];


  connectedCallback() {
    super.connectedCallback();
    this.initAuth();
    this.fetchClubStats();
    this.loadNewsBatch();
    this.setupFirestoreWithPersistence();
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log('beforeinstallprompt event fired');
      e.preventDefault(); // Prevent the mini-infobar from appearing
      this.deferredPrompt = e; // Save the event for triggering later
      console.log('Deferred prompt saved:', this.deferredPrompt);
      this.showInstallButton = true; // Show the install button
    });


    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        console.log('Service Worker ready:', registration);
      });
    }


    this.isSidebarOpen = false;
  }

  handleInstallClick() {
    if (this.deferredPrompt) {
      console.log('Showing install prompt');
      this.deferredPrompt.prompt(); // Show the install prompt
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        this.deferredPrompt = null; // Clear the saved event
        this.showInstallButton = false; // Hide the button after use
      });
    } else {
      console.log('No deferredPrompt available');
    }
  }


  setupFirestoreWithPersistence() {
    const app = getApp();
    if (!firestoreInitialized) {
      initializeFirestore(app, {
        localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
      });
      firestoreInitialized = true;
    } else {
      // Firestore is already initialized, just get the existing instance
      getFirestore(app);
    }
  }

  initAuth() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.isLoggedIn = true;
        this.fetchUserName(user.uid);
      } else {
        this.user = null;
        this.isLoggedIn = false;
        if (window.location.pathname !== resolveRouterPath('home')) {
          router.navigate(resolveRouterPath('login'));
        }
      }
    });
  }
  async fetchUserName(userId: string) {
    const db = getFirestore();
    const userRef = doc(db, `Player/${userId}`);
    try {
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        if (userData.first_name) {
          this.firstName = userData.first_name;
        }
        this.userType = userData.type || 'Player';
      } else {
        this.firstName = 'User';
        this.userType = 'Player';
      }
    } catch (error) {
      console.error('Error fetching user document:', error);
    }
  }

  async fetchClubStats() {
    const db = getFirestore();
    const statsRef = doc(db, "Club/Stats");
    try {
      const docSnap = await getDoc(statsRef);
      if (docSnap.exists()) {
        const statsData = docSnap.data();
        this.clubRunsScored = statsData.runs_scored || 0;
        this.clubWickets = statsData.wickets || 0;
        this.clubGames = statsData.games || 0;
        this.clubCatches = statsData.catches || 0;
      }
    } catch (error) {
      console.error('Error fetching club stats:', error);
    }
  }

  handleLogout() {
    const auth = getAuth();
    signOut(auth).then(() => {
      console.log('Logout successful');
      this.isLoggedIn = false;
      router.navigate(resolveRouterPath('login'));
    }).catch((error) => {
      console.error('Logout failed', error);
    });
  }

  async loadNewsBatch() {
    const db = getFirestore();
    const newsQuery = query(collection(db, 'News'), orderBy('date', 'desc'), limit(5));
    const newsSnapshot = await getDocs(newsQuery);
    const newsData = await newsSnapshot.docs.map((doc) => {
      const data = doc.data();
      return { ...data, id: doc.id };
    });
    this.newsItems = newsData;
    this.lastVisible = newsSnapshot.docs[newsSnapshot.docs.length - 1];
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  changeTab(tab: string) {
    this.activeTab = tab;
  }

  renderTabs() {
    const tabs = ['News', 'Availability', 'Events'];
    return html`
      <div class="tabs">
        ${tabs.map(tab => html`
          <div class="tab ${this.activeTab === tab.toLowerCase() ? 'active' : ''}" @click="${() => this.changeTab(tab.toLowerCase())}">
            ${tab}
          </div>
        `)}
      </div>
    `;
  }

  renderTabContent() {
    switch (this.activeTab) {
      case 'news':
        return html`
          <div class="tab-content">

            ${this.renderNewsFeed()}
          </div>
        `;
      case 'availability':
        return html`
        <app-header pageTitle="Availability 📅" ></app-header>
        <h3>Select your availabilty for the following events and fixtures:</h3>
          <div class="tab-content">
            <app-availability></app-availability>
          </div>
        `;
      case 'events':
        return html`
        <app-header pageTitle="Events 📈" ></app-header>
        <h3>Save events to your calendar or view them on maps:</h3>
       <div class="tab-content">
            <app-events></app-events>
          </div>
        `;
      default:
        return html`<div>Select a tab to view content.</div>`;
    }
  }
  renderNewsFeed() {
    return html`
      <app-header pageTitle="Cricket News 📰"></app-header>
      <div class="stats-grid">
        ${this.newsItems.map((news) => {
          // Get the date from the news item
          const date = new Date(news.date.seconds * 1000);

          // Manually format the date to dd/mm/yyyy
          const formattedDate = `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;

          return html`
            <div class="stat-card" @click="${() => this.openNews(news.id)}">
              <div class="stat-content">
                <div class="stat-value">${news.title}</div>
                <div class="stat-label">${news.content.substring(0, 100)}...</div>
                <div class="news-date">${formattedDate}</div>
              </div>
            </div>
          `;
        })}
      </div>
    `;
  }


  openNews(newsId: string) {
    router.navigate(`/news/${newsId}`);
  }


  render() {
    return html`
      <app-header></app-header>
       <div class="top-stats first-stat">
         <!-- Important message container -->


        ${this.renderTabs()}
        ${this.renderInstallButton()}
        ${this.renderTabContent()}

      </div>
    `;
  }

  renderImportantMessage() {
    // During the season
    const OffseasonActive = true; // You can change this condition dynamically

    if (OffseasonActive) {
      return html`
              <div class="message">
          <a href="/awards" class="link-to-awards">Click to VOTE for this years awards!</a>
        </div>

      `;
    } else {
      // Off-season message
      return html`
       <div class="message">
          <p>Next Event: <strong>Awards Night</strong></p>
          <p>Date: <strong>TBC</strong></p>
          <p>Time: <strong>TBC</strong></p>
          <p>Location: <strong>TBC</strong></p>
        </div>
      `;
    }
  }

  renderInstallButton() {
    if (this.showInstallButton) {
      return html`
        <div class="install-button-container">
          <button class="install-button" @click="${this.handleInstallClick}">
            Install App
          </button>
        </div>
      `;
    }
    return null;
  }

}
