import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { getFirestore, collection, query, getDocs, orderBy } from 'firebase/firestore';

interface EventItem {
  event: string;
  date: string;
  time: string;
  address: string;
}

@customElement('app-events')
export class AppEvents extends LitElement {
  @state() private upcomingEvents: EventItem[] = [];
  @state() private isLoading: boolean = true;

  private db = getFirestore();

  static styles = css`
  :host {

    color: #333;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding: 20px; /* Add padding for some breathing room around the content */
  }

  .event-item {
    display: block;
    background: #e6ffe6;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    margin-bottom: 10px;
    padding: 15px;
    transition: transform 0.2s;

  }

  .event-item:hover {
    transform: translateY(-2px);
  }

  .event-info {
    color: #444;
    margin-bottom: 4px;
  }

  .event-info div {
    font-size: 0.95em;
    color: #666;
  }

  .event-info div:first-child {
    font-size: 1.1em;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
  }

  button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }


  .loading {
    text-align: center;
    font-size: 1.2em;
  }
    .first-stat {
      padding-top: 75px; /* Adjust the value based on your header's height */
    }

    .event-heading {
      text-align: center;
      margin-bottom: 32px; /* Space between the heading and the event list */
    }

    .event-title {
      font-size: 2rem; /* Large font size for the main title */
      color: #206e3a; /* Color that fits the app's theme */
      margin-bottom: 0.5rem; /* Space between the title and the subtitle */
    }

    .event-subtitle {
      font-size: 1.2rem; /* Smaller font size for the subtitle */
      color: #555; /* A neutral color for the subtitle */
    }

  `;

  connectedCallback(): void {
    super.connectedCallback();
    this.fetchUpcomingEvents();
  }

  async fetchUpcomingEvents() {
    this.isLoading = true;
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to start of day for comparison

    const eventsRef = collection(this.db, 'Events');
    const q = query(eventsRef, orderBy("date"));
    const querySnapshot = await getDocs(q);

    const events: EventItem[] = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const eventDate = data.date ? new Date(data.date.seconds * 1000) : null;

      if (eventDate && eventDate >= today) {
        const formattedDate = eventDate.toLocaleDateString('default', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
        events.push({
          event: data.event,
          date: formattedDate,
          time: data.time,
          address: data.address
        });
      }
    });

    this.upcomingEvents = events;
    this.isLoading = false;
  }

  generateICS(event: EventItem) {
    const startDate = new Date(event.date);
    const startDateTime = `${startDate.getFullYear()}${String(startDate.getMonth() + 1).padStart(2, '0')}${String(startDate.getDate()).padStart(2, '0')}T${event.time.replace(':', '')}00`;

    const endDate = new Date(startDate);
    endDate.setHours(startDate.getHours() + 4); // assuming match lasts 4 hours
    const endDateTime = `${endDate.getFullYear()}${String(endDate.getMonth() + 1).padStart(2, '0')}${String(endDate.getDate()).padStart(2, '0')}T${String(endDate.getHours()).padStart(2, '0')}0000`;

    const icsData = `
BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY: ${event.event}
DESCRIPTION:${event.event} at ${event.address}
DTSTART:${startDateTime}
DTEND:${endDateTime}
LOCATION:${event.address}
END:VEVENT
END:VCALENDAR
    `.trim();

    // Create a blob from the iCalendar data
    const blob = new Blob([icsData], { type: 'text/calendar' });
    const url = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'event.ics';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  generateGoogleCalendarLink(event: EventItem) {
    const startDate = new Date(event.date);
    const startDateTime = `${startDate.getFullYear()}${String(startDate.getMonth() + 1).padStart(2, '0')}${String(startDate.getDate()).padStart(2, '0')}T${event.time.replace(':', '')}00`;

    const endDate = new Date(startDate);
    endDate.setHours(startDate.getHours() + 4); // assuming match lasts 4 hours
    const endDateTime = `${endDate.getFullYear()}${String(endDate.getMonth() + 1).padStart(2, '0')}${String(endDate.getDate()).padStart(2, '0')}T${String(endDate.getHours()).padStart(2, '0')}0000`;

    const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.event)}&dates=${startDateTime}/${endDateTime}&details=${encodeURIComponent(event.event)}+at+location=${encodeURIComponent(event.address)}`;

    window.open(url, '_blank');
  }

  renderEventDetails(): TemplateResult {
    return html`
      <app-header pageTitle="Events 📅"></app-header>
      <div class="top-stats first-stat">
        <div class="event-heading"></div>
        ${this.upcomingEvents.map((event) => html`
          <div class="event-item">
            <div class="event-info">
              <div>${event.event}</div>
              <div>${event.date}</div>
              <div>Time: ${event.time}</div>
              <a href="https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.address)}" target="_blank" rel="noopener noreferrer">
                <button>View on Maps</button>
              </a>
              <button @click="${() => this.generateGoogleCalendarLink(event)}">Add to Google Calendar</button>
              <button @click="${() => this.generateICS(event)}">Download Calendar File</button>
             </div>
          </div>
        `)}
      </div>
    `;
  }

  render(): TemplateResult {
    return html`
      <div>
        ${this.isLoading
          ? html`<p class="loading">Loading...</p>`
          : this.renderEventDetails()}
      </div>
    `;
  }
}
