import { LitElement, css } from 'lit';
import { customElement } from 'lit/decorators.js';

import './pages/app-home';
import '../public/components/header';
import '../public/components/logo';
import '../public/styles/global.css';
import { router } from './router';
import { requestPermission } from './pages/firebase-config'; // Import requestPermission from your firebase-config file

@customElement('app-index')
export class AppIndex extends LitElement {
  static styles = css`
    main {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 16px;
    }
  `;

  firstUpdated() {
    router.addEventListener('route-changed', () => {
      if ("startViewTransition" in document) {
        (document as any).startViewTransition(() => this.requestUpdate());
      } else {
        this.requestUpdate();
      }
    });

    // Request notification permission and get the token
    requestPermission();

    // Register the service worker
// Register the main service worker
// Register the main service worker (for VitePWA)
// Register the main service worker
// Register Firebase messaging service worker first
// Register Firebase messaging service worker
navigator.serviceWorker.register('/firebase-messaging-sw.js')
  .then((registration) => {
    console.log('Firebase Messaging Service Worker registered:', registration);
  })
  .catch((error) => {
    console.error('Firebase Messaging Service Worker registration failed:', error);
  });

// Delay the registration of the main service worker
setTimeout(() => {
  navigator.serviceWorker.register('/sw.js')
    .then((registration) => {
      console.log('Main Service Worker registered:', registration);
    })
    .catch((error) => {
      console.error('Main Service Worker registration failed:', error);
    });
}, 1000); // Adjust the delay as needed





  }

  render() {
    // router config can be found in src/router.ts
    return router.render();
  }
}
